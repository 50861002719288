import React from "react";
import Hero from "../Hero";
import Modelos from "../Modelos";
import modelo from "../assets/hero.webp";

const Home = () => {
  return (
    <>
      <Hero img={modelo} />
      <Modelos title="Cotiza tu Próximo Peugeot" />
    </>
  );
};

export default Home;
