import { createContext, useContext, useState } from "react";

/* 208 */
import p208card from "../assets/molelos/208/208.png";
import hero208 from "../assets/hero.webp";
import p2081 from "../assets/molelos/208/2081.png";
import p2082 from "../assets/molelos/208/2082.png";
import p2083 from "../assets/molelos/208/2083.png";
import p2084 from "../assets/molelos/208/2084.png";
import p2085 from "../assets/molelos/208/2085.png";
import p2086 from "../assets/molelos/208/2086.png";

/* Partner */
import partnercard from "../assets/molelos/partner/partner.png";
import heropartner from "../assets/molelos/partner/partnerhero.webp";
import partner1 from "../assets/molelos/partner/partner1.png";
import partner2 from "../assets/molelos/partner/partner2.png";
import partner3 from "../assets/molelos/partner/partner3.png";
import partner4 from "../assets/molelos/partner/partner4.png";

export const ConsContext = createContext();

export const useCons = () => {
  const context = useContext(ConsContext);
  if (!context) throw new Error("useCons must be used within a ConsContext");
  return context;
};

export const ConsContextProvider = ({ children }) => {
  const [popActive, setPopActive] = useState({
    active: false,
    clickModel: "",
  });

  const buttonData = (active, clickModel, modelName) => {
    setPopActive({
      active: active,
      clickModel: clickModel ? clickModel : "",
    });
    try {
      dataLayer.push({
        event: "CTA",
        model: modelName ? modelName : "Header",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const prices = {
    p208: {
      cuota: "97.680",
      anticipo: "2.644.880",
    },
    partner: {
      cuota: "117.500",
      anticipo: "2.674.920",
    },
  };

  const modelos = [
    {
      id: 1,
      link: "plan-208",
      nombre: "208 Like",
      modelo: "208",
      color: "#e6e6e6",
      text: "black",
      cuota: prices.p208.cuota,
      plan: `Plan 70/30`,
      /* plan: `Anticipo del $${prices.p208.anticipo}`, */
      text1: "Pagalo hast en 120 Cuotas",
      text2: "Retirá en Mes 6 con el 60% o en Mes 12 con 30%",
      img: hero208,
      galeria: [p2081, p2082, p2083, p2084, p2085, p2086],
      card: p208card,
      cupo: 6,
      dataButton: "PLAN 208",
      disabled: false,
    },
    {
      id: 2,
      link: "plan-partner",
      nombre: "Partner",
      modelo: "Partner",
      color: "#e6e6e6",
      text: "black",
      cuota: prices.partner.cuota,
      /* plan: `Anticipo del $${prices.partner.anticipo}`, */
      text1: "Pagalo en 84 Cuotas",
      text2: "Tomamos tu Usado Llave por Llave",
      img: heropartner,
      galeria: [partner1, partner2, partner3, partner4],
      card: partnercard,
      cupo: 3,
      dataButton: "PLAN PARTNER",
      disabled: false,
    },
  ];

  const modelsOptions = modelos.map((model) => {
    return {
      value: model.dataButton,
      text: `${model.dataButton} (Cuota $${model.cuota})`,
      disabled: model.disabled,
    };
  });

  console.log(modelsOptions);

  return (
    <ConsContext.Provider
      value={{
        popActive,
        setPopActive,
        prices,
        buttonData,
        modelos,
        modelsOptions,
      }}
    >
      {children}
    </ConsContext.Provider>
  );
};
