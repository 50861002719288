import React from "react";
import "./index.css";
import { useCons } from "../context/ConsContext";

const Hero = ({ img, dataButton, dataClass }) => {
  const { buttonData } = useCons();

  const month = new Date(new Date()).toLocaleString("es", {
    month: "long",
  });

  return (
    <div
      className={dataClass ? dataClass + " fullH" : "fullH"}
      style={img && { backgroundImage: `url(${img})` }}
    >
      <div className="container-lg">
        <div className="colorBox flex flexRow">
          <div className="textCol">
            <h4 className="relative">Solo por {month}</h4>
            <h1 className="relative">
              Tené tu Peugeot 0km
              <br />
              <span>Pagando en cuotas.</span>
            </h1>
            <h3 className="relative">
              Tené tu Peugeot con un plan claro, en pesos sabiendo lo que vas a
              pagar de principio a fin.
            </h3>
            <button
              className="btn whitebtn"
              onClick={() => buttonData(true, dataButton)}
            >
              Consultar ahora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
